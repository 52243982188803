<template>
  <ion-list mode="ios">
    <ion-item lines="none" @click="showPopover()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Update Phone Number</ion-label>
      <IconPeople class="icon-small" />
    </ion-item>
    <ion-item v-if="currentUser.enrollmentStatus != 'Stopped' && $can(I.MODIFY_ACCOUNT_PROGRAM)" lines="none" @click="confirmChangeUserStatus()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">{{ currentUser.active ? "Remove From " : "Add To " }} Program</ion-label>
      <IconDeactivate v-if="currentUser.active" class="icon-small" />
      <ion-icon v-else class="icon-black" name="ios-add-circle-outline"></ion-icon>
    </ion-item>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import { logOut, settings, addCircleOutline } from "ionicons/icons";
import IconPeople from "@/components/Global/Icons/IconPeople";
import IconDeactivate from "@/components/Global/Icons/IconDeactivate";
import { send as httpSend } from "@/services/Api";
import PopoverUpdatePhone from "@/components/Roster/PopoverUpdatePhone";

addIcons({
  "ios-logout": logOut.ios,
  "ios-settings": settings.ios,
  "ios-add-circle-outline": addCircleOutline.ios
});

export default {
  name: "PopoverRosterProgram",
  data() {
    return {
      programId: undefined,
      currentUser: Object
    };
  },
  components: {
    IconPeople,
    IconDeactivate
  },
  methods: {
    showPopover: function() {
      this.$ionic.popoverController.dismiss();

      this.$ionic.popoverController
        .create({
          component: PopoverUpdatePhone,
          mode: "ios",
          cssClass: "popover-width-lg",
          componentProps: {
            data: {
              accountId: this.currentUser.accountId,
              firstName: this.currentUser.beneFirstName,
              lastName: this.currentUser.beneLastName,
              mrn: this.currentUser.mrn,
              pcpFirstName: this.currentUser.pcpFirstName,
              pcpLastName: this.currentUser.pcpLastName,
              pcpTitle: this.currentUser.pcpTitle,
              pcpSuffix: this.currentUser.pcpSuffix
            }
          }
        })
        .then(m => m.present());
    },
    confirmChangeUserStatus() {
      this.$ionic.popoverController.dismiss();

      this.$ionic.alertController
        .create({
          header: this.currentUser.active ? "Confirm Removal" : "Confirm Addition",
          message: this.currentUser.active ? "Are you sure you want to remove <strong>" + this.currentUser.beneFirstName + " " + this.currentUser.beneLastName + "</strong> from the program? Once removed, the user can be re-added." : "Are you sure you want to add <strong>" + this.currentUser.beneFirstName + " " + this.currentUser.beneLastName + "</strong> to the program?",
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: this.currentUser.active ? "Remove From Program" : "Add To Program",
              handler: () => {
                this.changeUserStatus();
              }
            }
          ]
        })
        .then(a => a.present());
    },
    changeUserStatus() {
      const method = this.currentUser.active ? "delete" : "post";
      const path = this.currentUser.active ? document.config.SITUserAccount + this.currentUser.accountId + "/program/" + this.programId : document.config.SITUserAccount + "activate/" + this.currentUser.accountId + "/program/" + this.programId;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: this.currentUser.active ? "User was successfully removed" : "User was successfully added",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.$ionic.popoverController.dismiss();
          EventBus.$emit("reloadRosterView");
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to change user status",
              message: "Please try again later",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
