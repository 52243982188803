<template>
  <PrimePage titleAlignment="left" :loading="isLoading" :backToNamedRoute="'ProgramsList'" :backText="'Back to Programs'">
    <ion-row class="full-height">
      <ion-col>
        <ion-content>
          <div class="prime-full-width-container prime-roster-container full-height">
            <ion-row class="ion-align-items-center margin-top-thirty">
              <ion-col>
                <div class="text-muted">Program Overview</div>
                <h1 class="view-title">{{ programName }}</h1>
              </ion-col>
            </ion-row>
            <div v-if="isLoading" class="text-center pad-ten full-height">
              <div class="centerItems text-muted display-flex valign full-height full-width">
                <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
              </div>
            </div>
            <div v-else class="margin-top-ten">
              <ion-grid class="full-width" v-if="dashboardData">
                <ion-row class="ion-align-items-end">
                  <ion-col>
                    <ion-row class="margin-bottom-twenty">
                      <ion-col v-html="programDescription"> </ion-col>
                    </ion-row>
                    <ion-row class="margin-top-twenty">
                      <ion-col class="ion-align-self-stretch text-center card green-bg text-white display-flex valign" v-if="currentlyEnrolled.length > 0" :size="averageAge.length > 0 || (minAge.length > 0 && maxAge.length > 0) || females[0].value > 0 || males[0].value > 0 || unspecified[0].value > 0 ? '4' : ''">
                        <div class="full-width">
                          <h5>Currently Enrolled Patients</h5>
                          <span id="enrolledCount" class="hero-txt" v-tooltip="tooltip('currentlyEnrolled')">{{ currentlyEnrolled[0].value }}</span>
                        </div>
                      </ion-col>
                      <ion-col class="margin-left-twenty">
                        <ion-row class="card grid-program-breakdown text-center pad-twenty full-width ion-align-items-center">
                          <ion-col size="4" v-for="(metric, index) in metrics" :key="index">
                            <div class="text-muted text-bold">{{ metric.title }}</div>
                            <span class="hero-txt" v-tooltip="tooltip(metric.title)">{{ metric.value }}</span>
                          </ion-col>
                        </ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                  <ion-col size="4">
                    <div class="margin-left-twenty">
                      <div class="card pad-twenty full-height">
                        <h5>Patient Demographics</h5>
                        <div v-if="females[0].value > 0 || males[0].value > 0 || unspecified[0].value > 0" class="margin-top-fourty">
                          <div class="text-center margin-top-ten text-bold">Gender</div>
                          <pie-chart
                            class="margin-top-twenty"
                            :data="[
                              ['Female', females[0].value],
                              ['Male', males[0].value],
                              ['Unspecified', unspecified[0].value]
                            ]"
                            :colors="['#105db8', '#487ebc', '#b7cae1']"
                            legend="bottom"
                            height="160px"
                          ></pie-chart>
                        </div>
                        <div v-else class="margin-top-fourty">
                          <div class="text-center margin-top-ten text-muted">Demographic information will load once patients begin the program.</div>
                        </div>
                        <div v-if="averageAge.length > 0 || (minAge.length > 0 && maxAge.length > 0)">
                          <div class="text-center margin-top-thirty text-bold">Age</div>
                          <div v-if="averageAge.length > 0" class="text-center margin-top-twenty pill">
                            Average: <span class="text-bold">{{ Math.round(averageAge[0].value) }}</span>
                          </div>
                          <div v-if="minAge.length > 0 && maxAge.length > 0" class="text-center margin-top-twenty pill">
                            Range: <span class="text-bold">{{ minAge.length > 0 ? minAge[0].value : "" }}{{ maxAge.length > 0 ? " - " + maxAge[0].value : "" }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row class="margin-top-twenty pad-bottom-twenty">
                  <ion-col class="card table-card">
                    <div class="pad-top-twenty pad-lr-twenty pad-bottom-five">
                      <button id="programRoster" @click="goToNamedRoute('RosterProgram')" class="prime-button button-secondary button-block">
                        View Full Program Roster
                      </button>
                    </div>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <h6 class="margin-left-thirty">Program Roster</h6>
                      </ion-col>
                      <ion-col size="auto" class="pad-twenty"><span class="text-muted text-small">Showing Most Recently Added</span></ion-col>
                    </ion-row>
                    <div v-if="isLoadingRoster" class="pad-lr-twenty">
                      <SkeletonTable />
                    </div>
                    <div v-else>
                      <vue-good-table
                        ref="vgt-roster"
                        mode="remote"
                        :pagination-options="{
                          enabled: false
                        }"
                        :totalRows="totalRosterRecords"
                        :columns="columnsRoster"
                        :rows="rowsRoster"
                        styleClass="prime-table vgt-table"
                        :search-options="{
                          enabled: false,
                          placeholder: 'Search Patients'
                        }"
                        :sort-options="{
                          enabled: false
                        }"
                      >
                        <template slot="table-column" slot-scope="props">
                          <span v-if="props.column.field === 'enrollmentStatus'">
                            {{ props.column.label }}
                          </span>
                          <span v-else>{{ props.column.label }}</span>
                        </template>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'dob'">
                            {{ props.row.dob | moment("M/DD/YYYY") }}
                          </span>
                          <span v-else-if="props.column.field == 'enrollmentStatus'" class="text-center text-bold pill" :class="{ 'blue-pill': props.row.enrollmentStatus == 'Agreed', 'yellow-pill': props.row.enrollmentStatus == 'Stopped' }">
                            {{ props.row.enrollmentStatus ? props.row.enrollmentStatus : "--" }}
                          </span>
                          <span v-else-if="props.column.field == 'beneLastName'">
                            {{ makeRosterName(props.row) }}
                          </span>
                          <span v-else-if="props.column.field == 'optInDate'">
                            <span v-if="props.row.optInDate">
                              {{ props.row.optInDate | moment("M/D/YYYY") }}
                            </span>
                            <span v-else>
                              --
                            </span>
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </ion-col>
                  <ion-col class="card table-card margin-left-twenty">
                    <div class="pad-top-twenty pad-lr-twenty pad-bottom-five">
                      <button id="programOutreaches" @click="goToNamedRoute('OutreachesView')" class="prime-button button-secondary button-block">
                        View All Program Outreaches
                      </button>
                    </div>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <h6 class="margin-left-thirty">Program Outreaches</h6>
                      </ion-col>
                      <ion-col size="auto" class="pad-twenty"><span class="text-muted text-small">Showing Most Recent Outreaches</span></ion-col>
                    </ion-row>
                    <div v-if="isLoadingOutreaches" class="pad-lr-twenty">
                      <SkeletonTable />
                    </div>
                    <div v-else>
                      <vue-good-table
                        ref="vgt-outreaches"
                        mode="remote"
                        :pagination-options="{
                          enabled: false
                        }"
                        :totalRows="totalOutreachRecords"
                        :columns="columnsOutreach"
                        :rows="rowsOutreach"
                        styleClass="prime-table vgt-table"
                      >
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'pcpLastName'"> {{ renderPcpName(props.row) }} <IconInfoCircle class="prime-table-inline-icon prime-table-hover-icon" v-tooltip.top="{ content: '<span>' + '<small>Practice Phone #</small>' + '<br>' + '<strong>' + props.row.practice.officeNumber + '</strong></span>', classes: 'text-center' }"></IconInfoCircle> </span>
                          <span v-if="props.column.field == 'id'" class="text-center" style="width: 100%; display: inline-block;">
                            {{ props.row.id }}
                          </span>
                          <span v-else-if="props.column.label == 'Time Since Outreach'" class="text-center text-bold pill" :class="{ 'yellow-pill': showYellowTimeElapsedPill(props.row) }">
                            <span v-if="props.row.status == 'closed'">---</span>
                            <TimeSince v-else :startdate="props.row.createdAt"></TimeSince>
                          </span>
                          <span v-else-if="props.column.field == 'patientLastName'">
                            {{ makeORName(props.row) }}
                          </span>
                          <span v-else-if="props.column.field == 'dob'">
                            {{ props.row.patient.dob | moment("MM/DD/YYYY") }}
                          </span>
                          <span v-else-if="props.column.field == 'openTaskExists'" class="text-center text-bold pill" :class="{ 'green-pill': !props.row.openTaskExists, 'yellow-pill': props.row.openTaskExists }">
                            {{ props.row.openTaskExists ? "YES" : "NO" }}
                          </span>
                          <span v-else-if="props.column.field == 'clientPartnerReviewed'" class="text-center text-bold pill" :class="{ 'green-pill': props.row.clientPartnerReviewed }">
                            {{ renderClientPartnerReviewed(props.row) }}
                          </span>
                          <span v-else-if="props.column.label == 'Outreach At'" class="prime-table-small-date">
                            <span v-if="props.row.createdAt">{{ props.row.createdAt | moment("M/D/YYYY - h:mmA") }}</span>
                            <span v-else>---</span>
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </PrimePage>
</template>
<script>
import { send as httpSend } from "@/services/Api";
import IconGear from "@/components/Global/Icons/IconGear";
import SkeletonTable from "@/components/Global/SkeletonTable";
import TimeSince from "@/components/Global/TimeSince";
import PopoverRosterProgram from "@/components/Roster/PopoverRosterProgram";
import { addIcons } from "ionicons";
import { more } from "ionicons/icons";
import _ from "lodash";
import PrimePage from "@/components/Global/PrimePage";

addIcons({
  "md-more": more.md
});

export default {
  name: "ProgramDashboard",
  components: {
    IconGear,
    TimeSince,
    SkeletonTable,
    PrimePage
  },
  data() {
    return {
      dashboardData: undefined,
      isLoading: false,
      isLoadingRoster: false,
      isLoadingOutreaches: false,
      totalRosterRecords: 0,
      totalOutreachRecords: 0,
      programName: undefined,
      programDescription: undefined,
      columnsRoster: [
        {
          label: "Patient Name",
          field: "beneLastName",
          thClass: "prime-roster-nosort",
          tdClass: "bold",
          sortable: false
        },
        {
          label: "Date of Birth",
          field: "dob",
          thClass: "prime-roster-nosort",
          width: "110px",
          sortable: false
        },
        {
          label: "Status",
          field: "enrollmentStatus",
          thClass: "prime-roster-nosort",
          width: "160px",
          tdClass: "prime-roster-action-button-container",
          sortable: false
        },
        {
          label: "Added On",
          field: "optInDate",
          thClass: "prime-roster-nosort",
          width: "110px",
          sortable: false
        }
      ],
      rowsRoster: [
        // row data will get injected here
      ],

      columnsOutreach: [
        {
          label: "Patient Name",
          field: "patientLastName",
          thClass: "prime-roster-nosort",
          type: "text",
          tdClass: "bold",
          sortable: false
        },
        {
          label: "Outreach At",
          field: "createdAt",
          thClass: "prime-roster-nosort",
          type: "text",
          sortable: false
        },
        {
          label: "Open Task",
          field: "openTaskExists",
          thClass: "prime-roster-nosort",
          type: "boolean",
          sortable: false
        },
        {
          label: "Time Since Outreach",
          field: "createdAt",
          thClass: "prime-roster-nosort",
          sortable: false
        }
      ],
      rowsOutreach: [
        // row data will get injected here
      ],
      rosterServerParams: {
        id: "0",
        page: "1",
        perPage: "8",
        columnFilters: { enrollmentStatus: "" },
        sort: [{ field: "optInDate", type: "desc" }]
      },
      outreachesServerParams: {
        id: "0",
        page: "1",
        perPage: "8",
        columnFilters: { enrollmentStatus: "" },
        sort: [{ field: "opened", type: "desc" }]
      }
    };
  },
  async mounted() {
    this.loadData();
    this.loadRoster();
    this.loadOutreaches();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      const method = "get";
      const path = document.config.programsApi + this.$route.params.program_id + "/dashboard";

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.dashboardData = response.data;
          this.programName = response.data[0].programName;
          this.programDescription = response.data[0].description;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Overview Data",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async loadRoster() {
      this.isLoadingRoster = true;
      const method = "post";
      const path = document.config.programsApi + this.$route.params.program_id + "/roster";
      const payload = this.rosterServerParams;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.rowsRoster = response.data.results;
          this.totalRosterRecords = response.data.totalRecords;
          this.isLoadingRoster = false;
        })
        .catch(error => {
          this.isLoadingRoster = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Roster Data",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async loadOutreaches() {
      this.isLoadingOutreaches = true;

      const method = "post";
      // const path = document.config.SITPractice;
      const path = document.config.getTasksByProgramId + this.$route.params.program_id;
      const payload = this.outreachesServerParams;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.rowsOutreach = response.data.results;
          this.totalOutreachRecords = response.data.totalRecords;
          this.isLoadingOutreaches = false;
        })
        .catch(error => {
          this.isLoadingOutreaches = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Outreach Data",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    goToPatientRosterWithURLParam(urlParam) {
      this.$router.push({
        name: "RosterProgram",
        params: { program_id: this.$route.params.program_id },
        query: { status: urlParam }
      });
    },
    goToNamedRoute(routeName) {
      this.$router.push({
        name: routeName,
        params: { program_id: this.$route.params.program_id }
      });
    },
    makeRosterName(rowObj) {
      return rowObj.beneLastName + ", " + rowObj.beneFirstName;
    },
    makeORName(rowObj) {
      return (rowObj.patient.lastName ? rowObj.patient.lastName + ", " : "") + (rowObj.patient.firstName ? rowObj.patient.firstName : "");
    },
    showYellowTimeElapsedPill(row) {
      const isInactive = !row.inProgress && !row.openTaskExists;
      return row.closed == null && !isInactive;
    },
    showYellowAssignedPill(row) {
      return row.openTaskExists == true || row.inProgress;
    },
    renderRequiresResponse(rowObj) {
      let requiresResponse;
      if (rowObj.closed) {
        requiresResponse = "COMPLETED";
      } else if (rowObj.inProgress == false && rowObj.taskExists == false) {
        requiresResponse = "NO";
      } else {
        requiresResponse = "YES";
      }

      return requiresResponse;
    },
    tooltip(name) {
      return { content: this.$copy.tooltips[_.camelCase(name).replace(/ /g, "")], classes: ["max-width-300", "text-center"] };
    },
    showPopover: function(ev, currentUser) {
      this.$ionic.popoverController
        .create({
          component: PopoverRosterProgram,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              currentUser: currentUser
            }
          }
        })
        .then(m => m.present());
    }
  },
  computed: {
    metrics: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.type == "metrics";
      });
    },
    demographics: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.type == "demographics";
      });
    },
    currentlyEnrolled: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Currently Enrolled Patients";
      });
    },
    males: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Male";
      });
    },
    females: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Female";
      });
    },
    unspecified: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Unspecified";
      });
    },
    averageAge: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Average Age";
      });
    },
    maxAge: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Maximum Age";
      });
    },
    minAge: function() {
      return this.dashboardData.filter(function(dashParam) {
        return dashParam.title == "Minimum Age";
      });
    }
  }
};
</script>

<style scoped>
.hero-txt {
  font-size: 45px;
  font-weight: 500;
}
.card {
  border-radius: 10px;
  box-shadow: var(--prime-card-shadow) !important;
  background-color: #fff;
}
.table-card {
  min-height: 400px;
}

.green-bg {
  background-color: #81b948;
}
/* good table row-specific stuff */

small {
  font-size: 12px;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
.grid-program-breakdown {
  min-height: 280px;
}
</style>
