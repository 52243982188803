<template>
  <ion-grid class="custom-skeleton">
    <ion-row class="bottom-divider">
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-ten"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-ten"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-ten"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
    <ion-row class="margin-top-ten">
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
    <ion-row>
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
    <ion-row>
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
    <ion-row>
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
    <ion-row>
      <ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
      <ion-col class="margin-left-five"><ion-skeleton-text animated></ion-skeleton-text></ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
export default {
  name: "SkeletonTable"
};
</script>
